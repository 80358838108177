<template>
  <div>
    <tab-bar-wrapper/>
    <div id="grid-container" class="grid-extended">
      <grid-wrapper
        :contextMenuItems="contextMenuItems"
        :columns="columns"
        :query="query"
        :filter="filter"
        :annotations="annotations"
        :nested-query="nestedQuery"
        :nested-filters="nestedFilters"
        :nested-annotations="nestedAnnotations"
        :is-row-master-function="rowMasterFunction"
        :componentName="$options.name"
        :nested-grid="true"
        :nestedColumns="nestedColumns"
        :nestedContextMenuItems="nestedContextMenuItems"
        @ready="ready"
        @nestedReady="nestedReady"
        @setComments="setComments"
        @cell-key-down="gridKbEventSender"
      />
    </div>
    <action-bar-wrapper
     :commentList="commentList"
     @saveComments="saveComments"
    />
    <v-dialog
      lazy
      width="800"
      v-model="showDialog"
      persistent
    >
      <add-parts-to-rma
        v-if="dialog.component === 'addToRMA'"
      />
      <pdf-viewer
        v-if="dialog.component === 'pdf-viewer'"
        :data="pdfData"
        :custom="pdfRules"
        @close="$store.dispatch('grid/resetDialog')"
      />
    </v-dialog>
  </div>
</template>

<script>
import TabBarWrapper from '@/components/wrappers/tabBarWrapper.vue'
import ActionBarWrapper from '@/components/wrappers/actionBarWrapper.vue'
import GridWrapper from '@/components/wrappers/gridWrapper.vue'
import addToRMA from '@/components/dialogs/addToRMA'
import grid from '@/components/mixins/grid'
import columns from '@/components/mixins/columns'
import { GET_RMA_FOR_PDF, GRID_GET_SALE_RMAS as SALES_RMAS } from '@/api/graphql/Constants/RMAs'
import mutateRMAs from '@/api/graphql/mutationsJS/mutateRMAs'
import { GRID_GET_SALE_RMA_ITEMS } from '@/api/graphql/Constants/RMAs'
import { COLUMNS__SALES_RMA, COLUMNS__SALES_RMA_NESTED } from '@/lib/agGridColumnConfiguration'
import { CONTEXT_MENU__SALES_RMA, CONTEXT_MENU__SALES_RMA_NESTED } from '@/lib/agGridContextMenuConfigurations'
import { GridKbEventHandler } from '@/lib/eventHandlers'
import { KB_SHORTCUT__SALES_RMA, KB_SHORTCUT__SALES_RMA_NESTED } from '@/lib/agGridKbShortCuts'
import { RowMasterFunctionForItemCountAnnotation } from '@/lib/helpers'
import { prepPdfData } from '@/lib/PdfPrep'
import ViewPDF from '@/components/dialogs/ViewPDF'
export default {
  name: 'srma',
  mixins: [grid, columns, mutateRMAs],
  components: {
    'tab-bar-wrapper': TabBarWrapper,
    'action-bar-wrapper': ActionBarWrapper,
    'grid-wrapper': GridWrapper,
    'add-parts-to-rma': addToRMA,
    'pdf-viewer': ViewPDF
  },
  computed: {
    showDialog: function () {
      return this.dialog.app === 'rma'
    }
  },
  data () {
    return {
      editedData: [],
      contextMenuItems: (params) => CONTEXT_MENU__SALES_RMA(params, this),
      nestedContextMenuItems: (params) => CONTEXT_MENU__SALES_RMA_NESTED(params),
      commentList: {
        'internal_comment': {
          name: 'internal_comment',
          label: 'Internal',
          text: '',
          editable: true,
          show: true
        },
        'contract_comment': {
          name: 'contract_comment',
          label: 'Contract',
          text: '',
          editable: true,
          show: true
        },
        'return_reason': {
          name: 'return_reason',
          label: 'Reason',
          text: '',
          editable: false,
          show: true
        },
        'id': false
      },

      query: SALES_RMAS,
      filter: [],
      annotations: [
        {
          name: 'itemCount',
          aggr_type: 'COUNT',
          aggr_field: 'rma_items'
        }
      ],

      nestedQuery: GRID_GET_SALE_RMA_ITEMS,
      nestedFilters: id => [{ key: 'rma__id', value: id }],
      nestedAnnotations: [],

      columns: COLUMNS__SALES_RMA,

      nestedColumns: COLUMNS__SALES_RMA_NESTED,

      pdfData: {},
      pdfRules: []
    }
  },
  methods: {
    gridKbEventSender (params) {
      GridKbEventHandler(params, KB_SHORTCUT__SALES_RMA(params, this), KB_SHORTCUT__SALES_RMA_NESTED())
    },

    rowMasterFunction (data) {
      return RowMasterFunctionForItemCountAnnotation(data.annotations)
    },

    async placeInNextStatus (id, status) {
      await this.updateSalesRmaStatus(id, status)
      this.$store.dispatch('data/changeRefresh', { bool: true }, { root: true })
    },

    async viewContract (id) {
      this.$store.dispatch('notifications/createSnackbar', {
        message: 'Grabbing data...',
        color: 'info'
      })
      try {
        const response = await this.$apollo.query({
          query: GET_RMA_FOR_PDF,
          variables: { id: id }
        })
        if (response.data.rma_rma) {
          const prepped = prepPdfData.Rma(response.data.rma_rma)
          this.pdfData = prepped.pdf
          this.pdfRules = prepped.rules
          await this.$store.dispatch('grid/changeDialog', { app: 'rma', component: 'pdf-viewer' })
          await this.$store.dispatch('notifications/hideSnackbar')
        }
      } catch (error) {
        const message = error.message ? error.message : error
        this.$store.dispatch('notifications/createSnackbar', {
          message: message,
          color: 'error'
        })
      }
    }
  }
}
</script>
